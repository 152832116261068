<template>
    <div id="landmarks">
      <div class="container-fluid">
        <!-- Add Edit Section -->
        <!-- @submit="onSubmit" @reset="onReset" -->
        <div class="add-section" v-if="canAccess(['answer-message-contactUs'])">
          <b-modal id="answer-modal" size="lg" :title="$t('sidebar.contact')" :cancel-title="$t('close')" :ok-title="$t('save')" @ok="submit">
            <b-form class="d-grid grid-cols-1 gap-5">
              <b-row>
                <b-col sm="12" lg="6">
                  <div class="user-info bg-light p-3">
                    <div class="section-title mb-4">
                      <h4 class="d-flex align-items-center"> <i class="ri-shield-user-line text-primary"></i> {{
                          $t('user-info')
                        }}</h4>
                    </div>
                    <div class="info d-flex align-items-center justify-content-center flex-column ">
                    <div class="img mb-1">
                      <img src="@/assets/images/contact/01.jpg" class="rounded shadow mx-auto" width="150" alt="">
                    </div>
                    <h6 class="text-center font-weight-bold">{{ info.full_name}}</h6>
                    <ul class="d-flex align-items-center justify-content-center flex-wrap" style="gap: 10px;">
                      <li class="d-flex align-items-center" style="gap: 5px;">
                        <i class="ri-mail-open-line"></i>
                        {{ info.email }}
                      </li>
                      <li class="d-flex align-items-center" style="gap: 5px;">
                        <i class="ri-phone-line"></i>
                        {{ info.phone }}
                      </li>
                      <li class="d-flex align-items-center" style="gap: 5px;">
                        <i class="ri-map-pin-line"></i>
                        {{ info.address }}
                      </li>
                    </ul>
                    <p v-if="question" class="text-center"> {{ info.full_name}} {{ $t('ask') }} :  {{ info.message }}</p>
                  </div>
                  </div>
                </b-col>
                <b-col sm="12" lg="6">
                  <div class="answer-form p-3">
                    <div class="section-title mb-4">
                        <h4 class="d-flex align-items-center"> <i class="ri-question-answer-line text-primary"></i>
                          {{ $t('admin-answer') }}</h4>
                      </div>
                    <div class="feild">
                      <label for="" class="mb-1">{{ $t('answer') }}</label>
                      <b-form-textarea
                        rows="5"
                        type="text"
                        v-model="payload.answer"
                        required
                      ></b-form-textarea>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </b-modal>
        </div>
        <!-- Filters Section -->
        <div class="filters-section">
          <iq-card class="w-100">
            <template v-slot:body>
              <div class="filter-container" @change="filtering">
                <b-row style="gap: 10px 0px;">
                  <b-col sm="12" lg="4">
                    <b-form-input
                      type="text"
                      :placeholder="$t('filter-name')"
                      v-model="filterData.full_name"
                      required
                    ></b-form-input>
                  </b-col>
                  <b-col sm="12" lg="4">
                    <b-form-input
                      type="text"
                      :placeholder="$t('filter-email')"
                      v-model="filterData.email"
                      required
                    ></b-form-input>
                  </b-col>
                  <b-col sm="12" lg="4">
                    <b-form-input
                      type="text"
                      :placeholder="$t('filter-phone')"
                      v-model="filterData.phone"
                      required
                    ></b-form-input>
                  </b-col>
                </b-row>
              </div>
            </template>
          </iq-card>
        </div>
        <!-- Tabel Section -->
        <div class="table-sections">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('sidebar.contact') }}</h4>
            </template>
            <template v-slot:body>
              <b-table responsive v-if="canAccess(['get-contactUs','answer-message-contactUs','delete-contactUs'])" :items="contacts.data" :fields="fields" class="mb-0 table-borderless">
                <template v-slot:cell(actions)="data">
                    <div class="actions-list d-flex align-items-center">
                      <b-button v-if="canAccess(['answer-message-contactUs'])" v-b-tooltip.top="'Show Details'" variant=" iq-bg-success" @click="update(data.item)"  size="md"><i class="ri-eye-fill p-0"></i></b-button>
                      <!-- <b-button v-if="canAccess(['delete-contactUs'])" v-b-tooltip.top="'Remove'" variant=" iq-bg-danger"  size="md" @click="remove(data.item.id)"><i class="ri-delete-bin-line p-0"></i></b-button> -->
                    </div>
                  </template>
              </b-table>
            </template>
          </iq-card>
        </div>
        <!-- Pagination Section -->
        <div class="pagination">
          <iq-card class="w-100 d-flex justify-content-center">
            <template v-slot:body>
              <b-pagination
                  v-model="current_page"
                  :total-rows="contacts.total"
                  :per-page="filterData.take"
              ></b-pagination>
            </template>
          </iq-card>
        </div>
      </div>
    </div>
  </template>
<script>
export default {
  name: 'landmarks',
  data () {
    return {
      current_page: 1,
      filterData: {
        skip: 0,
        take: 10,
        full_name: '',
        phone: '',
        email: ''
      },
      info: {},
      payload: {
        answer: ''
      }
    }
  },
  computed: {
    fields () {
      return [
        {
          label: this.$t('name'),
          key: 'full_name'
        },
        {
          label: this.$t('address'),
          key: 'address'
        },
        {
          label: this.$t('phone'),
          key: 'phone'
        },
        {
          label: this.$t('answer-by'),
          key: 'answered_by.name'
        },
        {
          label: this.$t('updated-date'),
          key: 'updated_at'
        },
        {
          label: this.$t('actions'),
          key: 'actions'
        }
      ]
    }
  },
  methods: {
    filtering () {
      this.getContacts(this.filterData)
    },
    update (data) {
      this.info = data
      this.payload.answer = data.answer
      this.$bvModal.show('answer-modal')
    },
    submit (bvModalEvent) {
      bvModalEvent.preventDefault()
      this.sendAnswer({
        id: this.info.id,
        answer: this.payload.answer,
        _method: 'PUT'
      }).then(() => {
        this.$bvModal.hide('answer-modal')
        this.payload = this.resetObject(this.payload)
        this.getContacts()
      })
    },
    remove (id) {
      this.confermMsg(() => {
        this.removeContacts(id)
      })
    }
  },
  watch: {
    current_page: {
      handler: function (value) {
        this.filterData.skip = value === 1 ? 0 : (this.filterData.take * value) - this.filterData.take
        this.filtering()
      },
      deep: true
    }
  },
  mounted () {
    this.getContacts()
  }
}
</script>
<style>
.user-info {
  min-height: 400px;
  background-color: #e0e0e0;
  border-radius: 5px;
}

</style>
